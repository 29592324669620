import home from "./pages/home";
import page2 from "./pages/page2";
import page3 from "./pages/page3";
import Error from "./pages/page404";

export const allRoutes = [
  {
    path: "/home",
    component: home,
    exact: true,
  },
  {
    path: "/page2",
    component: page2,
    exact: true,
  },
  {
    path: "/page3",
    component: page3,
    exact: true,
  },
  {
    path: "/*",
    component: Error,
    exact: false,
  },
];

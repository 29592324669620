import { FunctionComponent,useState } from "react";
export type WalkthroughVisualType = {
  className?: string;
};

const WalkthroughVisual: FunctionComponent<WalkthroughVisualType> = ({
  className = "",
}) => {
  return (
    <>
    <div
     className="self-stretch flex flex-row items-center justify-center pt-0 px-5 pb-16 box-border max-w-full">
    <div className="w-[501px] flex flex-col items-center justify-start gap-[17px] max-w-full">
      <div className="self-stretch flex flex-row items-start justify-center py-0 pr-[21px] pl-5">
        <b className="relative tracking-[0.04em] leading-[20px] uppercase  mq450:leading-[16px]">
          still having confusion?
        </b>
      </div>
      <b className="h-20 relative mq750:text-13xl text-21xl tracking-[0.01em] leading-[40px] inline-block text-system-black ">
        <p className="m-0 whitespace-pre-wrap">
          Here’s a complete walkthrough
        </p>
        <p className="m-0">of our resume builder</p>
      </b>
    </div>
  </div>
    
    <div
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[130px]  ${className}`}
    >
      <img  className="cursor-pointer1 mq1050:w-[100%]" alt="" src="/walkthru-1.png" />
    </div>
    </>
  );
};

export default WalkthroughVisual;

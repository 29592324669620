import { FunctionComponent, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export type FrameComponent10Type = {
  className?: string;
};

const Faq: FunctionComponent<FrameComponent10Type> = ({ className = "" }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div
      className={`self-stretch h-[783px] relative bg-neutral-white overflow-hidden1 shrink-0 max-w-full z-[3] text-left text-xl text-darkgoldenrod font-buttons-button-lg ${className}`}
    >
      <img
        className="absolute top-[0px] left-[0px] w-[1440px] h-[745px] object-cover hidden"
        alt=""
        src="/image-311@2x.png"
      />
      <div className="absolute top-[0px] left-[999px] box-border w-px h-[935px] z-[1] border-r-[1px] border-dashed border-gray-1100" />
      <div className="absolute top-[0px] left-[417px] box-border w-px h-[935px] z-[1] border-r-[1px] border-dashed border-gray-1100" />
      <div className="absolute top-[0px] left-[0px] bg-neutrals-n0 w-full flex flex-row items-start justify-center py-20 px-5 box-border min-h-[815px] max-w-full">
        <div className="h-[815px] w-[1440px] relative bg-neutrals-n0 hidden max-w-full" />
        <div className="w-[752px] overflow-hidden shrink-0 flex flex-col mq750:items-center mq750:justify-center items-center justify-start gap-[56px] max-w-full z-[2] mq750:gap-[28px]">
          <div className="flex flex-col mq750:items-center mq750:justify-center items-center justify-start gap-[17px] max-w-full">
            <b className="relative tracking-[0.04em] leading-[20px] uppercase inline-block min-w-[36px]">
              FAQ
            </b>
            <b className="relative text-21xl tracking-[0.01em] leading-[40px] capitalize text-neutrals-n90 text-center ] mq750:text-13xl mq750:leading-[32px]">
              <p className="m-0">Answers to frequently</p>
              <p className="m-0">Asked Questions</p>
            </b>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[15px] max-w-full text-lg text-neutrals-n60">
            <Accordion
              sx={{
                borderColor: "#e2e2e3",
                boxShadow: "none",
                // margin: "5px",
              }}
            >
              <AccordionSummary
                sx={{
                  paddingLeft: "25px",
                  letterSpacing: "-0.01em",
                  lineHeight: "18px",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f3f3f3",
                  twBorderOpacity: 1,
                  border: "#e2e2e3 1px solid",
                  borderRadius: "5px",
                  borderBottomColor: "#e2e2e3",
                  fontWeight: 500,
                  opacity: 0.75,
                  // borderBottom: "none",
                  minHeight: "54px",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                How does ResAI customize resumes for different industries?
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingTop: "0px",
                  paddingLeft: "25px",
                  paddingBottom: "17px",
                  borderColor: "#e2e2e3",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f6f6f6",
                  fontWeight: 500,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                borderColor: "#e2e2e3",
                boxShadow: "none",
                '&::before': {
                  opacity:0
                }
                // margin: "5px",
              }}
            >
              <AccordionSummary
                sx={{
                  paddingLeft: "25px",
                  letterSpacing: "-0.01em",
                  lineHeight: "18px",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f3f3f3",
                  twBorderOpacity: 1,
                  border: "#e2e2e3 1px solid",
                  borderRadius: "5px",
                  borderBottomColor: "#e2e2e3",
                  fontWeight: 500,
                  opacity: 0.75,
                  minHeight: "54px",
                  // borderBottom: "none",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Is ResAI compatible with all devices and browsers?
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingTop: "0px",
                  paddingLeft: "25px",
                  paddingBottom: "17px",
                  borderColor: "#e2e2e3",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f6f6f6",
                  fontWeight: 500,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                borderColor: "#e2e2e3",
                boxShadow: "none",
                '&::before': {
                  opacity:0
                }
              }}
            >
              <AccordionSummary
                sx={{
                  paddingLeft: "25px",
                  letterSpacing: "-0.01em",
                  lineHeight: "18px",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f3f3f3",
                  twBorderOpacity: 1,
                  border: "#e2e2e3 1px solid",
                  borderRadius: "5px",
                  borderBottomColor: "#e2e2e3",
                  fontWeight: 500,
                  opacity: 0.75,
                  minHeight: "54px",
                  //borderBottom: "none",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Can I download my resume in multiple formats?
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingTop: "0px",
                  paddingLeft: "25px",
                  paddingBottom: "17px",
                  borderColor: "#e2e2e3",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f6f6f6",
                  fontWeight: 500,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>

            <Accordion
              defaultExpanded
              sx={{
                borderColor: "#e2e2e3",
                boxShadow: "none",
                '&::before': {
                  opacity:0
                }
              }}
            >
              <AccordionSummary
                sx={{
                  paddingLeft: "25px",
                  letterSpacing: "-0.01em",
                  lineHeight: "18px",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f3f3f3",
                  twBorderOpacity: 1,
                  border: "#e2e2e3 1px solid",
                  borderRadius: "5px",
                  borderBottomColor: "#e2e2e3",
                  fontWeight: 500,
                  opacity: 0.75,
                  minHeight: "54px",
                  //borderBottom: "none",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Does ResAI offer any guidance or tips for resumes?
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingTop: "0px",
                  paddingLeft: "25px",
                  paddingBottom: "17px",
                  borderColor: "#e2e2e3",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f6f6f6",
                  fontWeight: 500,
                }}
              >
                Absolutely! ResAI provides AI assistance for crafting resumes
                and offers expert guidance based on extensive knowledge,
                ensuring your resume shines.
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                borderColor: "#e2e2e3",
                boxShadow: "none",
                '&::before': {
                  opacity:0
                }
              }}
            >
              <AccordionSummary
                sx={{
                  paddingLeft: "25px",
                  letterSpacing: "-0.01em",
                  lineHeight: "18px",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f3f3f3",
                  twBorderOpacity: 1,
                  border: "#e2e2e3 1px solid",
                  borderRadius: "5px",
                  borderBottomColor: "#e2e2e3",
                  fontWeight: 500,
                  opacity: 0.75,
                  //  borderBottom: "none",
                  minHeight: "54px",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Is my personal data secure with ResAI?
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingTop: "0px",
                  paddingLeft: "25px",
                  paddingBottom: "17px",
                  borderColor: "#e2e2e3",
                  backdropFilter: "blur(40px)",
                  backgroundColor: "#f6f6f6",
                  fontWeight: 500,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>

            {/* <div className="self-stretch [backdrop-filter:blur(40px)] rounded-md bg-gray-800 flex flex-row items-center justify-between py-[17px] px-6 gap-[20px] border-[1px] border-solid border-neutrals-n10">
              <div className="relative tracking-[-0.01em] leading-[18px] font-medium">
                How does ResAI customize resumes for different industries?
              </div>
              <img
                className="h-1.5 w-3 relative"
                alt=""
                src="/f-a-q-dropdown-icons.svg"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;

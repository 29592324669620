import { FunctionComponent } from "react";

export type FrameComponent3Type = {
  className?: string;
};

const AIPowered: FunctionComponent<FrameComponent3Type> = ({
  className = "",
}) => {
  return (
    <>
      <div
        className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[54px] box-border max-w-full shrink-0 text-center text-53xl text-neutrals-n90 font-buttons-button-lg ${className}`}
      >
        <div className="flex flex-col items-center justify-start gap-[32px] shrink-0 max-w-full z-[2] mq750:gap-[16px]">
          <div className="flex flex-col items-center justify-start gap-[15px] max-w-full">
            <div className="relative tracking-[-0.01em] leading-[74px] capitalize mq450:text-24xl mq450:leading-[44px] mq750:text-39xl mq750:leading-[59px]">
              <p className="m-0">
                <span className="font-medium">ultimate Free</span>
                <b className="font-buttons-button-lg"> AI-powered</b>
              </p>
              <p className="m-0 font-medium">{`resume builder `}</p>
            </div>
            <div className="mq1050:h-[auto] h-[11px] relative text-lg leading-[18px] text-neutrals-n60 inline-block max-w-full">
              Resume maker to generate a resume aligned with your job
              description
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[32px] text-lg text-neutral-white">
            <div className="cursor-pointer rounded-md bg-neutrals-n90 flex flex-row items-center justify-center py-[19px] px-[52px] whitespace-nowrap">
              <div className="relative leading-[18px] font-semibold">
                Build Your Resume
              </div>
            </div>
            <div className="self-stretch hidden flex-row items-center justify-start py-2 pr-0 pl-[22px] gap-[16px] text-neutrals-n90 border-l-[2px] border-solid border-goldenrod">
              <div className="relative leading-[18px] font-semibold">
                Get Your Resume Score
              </div>
              <img
                className="h-[5.1px] w-[10.2px] relative"
                alt=""
                src="/group-1000009070.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIPowered;
